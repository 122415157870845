import Sortable from 'sortablejs';
import {GoogleLogin} from "./googleLogin";


let globalEntities = []; // Stocke temporairement les entités qui transitent

// Assurez-vous que les entités sont bien chargées dans `main.js` avant d'utiliser cette fonction
function openEditPanel(entityType) {
    const entities = window.getEntities();

    if (!entities || (!entities.soundboard && !entities.ambiance)) {
        return;
    }


    const panel = document.getElementById('rightPanel');
    const overlay = document.getElementById('overlay');
    const panelTitle = document.getElementById('panelTitle');
    const panelContent = document.getElementById('panelContent');

    panel.classList.remove('translate-x-full');
    overlay.classList.remove('hidden');
    overlay.classList.add('opacity-100');

    let data = [];
    if (entityType === 'soundboard' && entities.soundboard.length > 0) {
        data = entities.soundboard;
    } else if (entityType === 'ambiance' && entities.ambiance.length > 0) {
        data = entities.ambiance;
    } else {
        panelContent.innerHTML = '<p class="text-gray-500">Chargement des données...</p>';
        return;
    }

    panelTitle.textContent = entityType === 'soundboard' ? 'Edit Soundboard' : 'Edit Ambiance Sounds';
    panelContent.innerHTML = generateEntityList(data, entityType);

    if (document.getElementById('sortableList')) {
        Sortable.create(document.getElementById('sortableList'), {
            animation: 150,
            onEnd: (event) => {
                const oldIndex = event.oldIndex;
                const newIndex = event.newIndex;
                console.log(`Moved item from ${oldIndex} to ${newIndex}`);
                // You can update the order of `entities` here if needed
            },
        });
    }

    document.querySelectorAll('.editableEntity').forEach((item) => {
        item.addEventListener('click', (e) => {
            const index = e.currentTarget.dataset.index;
            openEditPopup(index); // Pass the row index to the popup function
        });
    });
}

function saveEntity(index, soundType) {
    console.log(soundType)
    const entity = globalEntities[index];
    if (!entity) {
        console.error('Entity not found!');
        return;
    }

    // Récupérer les données du formulaire dans la modal
    const soundName = document.getElementById('soundName').value;
    const isVisible = document.getElementById('toggleVisible').checked;

    // Mettre à jour l'entité avec les nouvelles valeurs
    entity.display_name = soundName;
    entity.visible = isVisible;

    // Envoyer les données au serveur
    fetch('/update-user-sounds', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: JSON.parse(localStorage.getItem('googleLoginState'))?.userId,
            soundsType: soundType,
            sounds: [entity],
        }),
    })
        .then((response) => {
            if (response.ok) {
                console.log('Data saved successfully');
                closeEditSoundModal(); // Fermez la modal après la sauvegarde
            } else {
                console.error('Failed to save data');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
}

function openEditSoundModal(index, soundType) {
    console.log(soundType)
    const entity = globalEntities[index]; // Récupère l'entité via l'index
    if (!entity) {
        console.error('Entity not found!');
        return;
    }

    const modal = document.getElementById('edit-sound-modal');

    if (!modal) {
        console.error('Modal not found!');
        return;
    }

    // Remplir les champs avec les données du son
    document.getElementById('soundName').value = entity.display_name || '';
    document.getElementById('soundFile').value = ''; // Réinitialise le champ fichier
    document.getElementById('toggleVisible').checked = entity.visible; // Définit la visibilité actuelle

    // Afficher la modal
    modal.classList.remove('hidden');

    // Ajoutez un gestionnaire pour sauvegarder les modifications
    document.getElementById('saveEntity').onclick = () => saveEntity(index, soundType);
}

function generateEntityList(entities, entityType) {

    console.log(entityType)

    globalEntities = entities; // for management and transmission
    entities = Array.isArray(entities) ? entities : []; // Protégez contre une valeur indéfinie

    return `
        <ul id="sortableList" class="space-y-1">
            ${entities.map((entity, index) => `
                 <li
                    class="draggable-item editableEntity cursor-pointer flex items-center space-x-2"
                    onclick="openEditSoundModal(${index}, '${entityType}')"
                >
                    <div class="w-full p-2 border border-gray-300 rounded text-slate-800 flex items-center justify-between">
                            <span>${entity.display_name || entity.ambianceSound?.filename}</span>
                        <div class="flex items-center">
                            <span class="badge ${entity.origin === "default" ? "blueBadge" : "purpleBadge"} px-2 py-1 rounded text-sm font-semibold">
                                ${entity.origin === "default" ? "Default" : "Custom"}
                            </span>
                            <button data-index="${index}" class="toggle-visibility-button ">
                                ${
                                    entity.visible
                                    ? `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="buttonStyle size-5">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                      </svg>`
                                    : `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="buttonStyle size-5">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                      </svg>`
                                }
                            </button>
                        </div>
                    </div>
                </li>`).join('')}
        </ul>
    `;
}


function toggleRightPanel(forceClose = false) {
    const panel = document.getElementById('rightPanel');
    const overlay = document.getElementById('overlay');

    // Ferme toujours si forceClose est activé ou si le panneau est ouvert
    if (forceClose || !panel.classList.contains('translate-x-full')) {
        panel.classList.add('translate-x-full');
        overlay.classList.add('hidden');
        overlay.classList.remove('opacity-100');
    }
}


function openEditPopup(index) {
    const entities = window.getEntities();
    const entity = entities.soundboard[index] || entities.ambiance[index]; // Find the entity by index
    if (!entity) return;

    // Populate modal with entity details
    document.getElementById('soundName').value = entity.display_name || entity.ambianceSound?.filename;

    // Show the modal
    const modal = document.getElementById('editModal');
    modal.classList.remove('hidden');
}

function closeEditSoundModal() {
    const modal = document.getElementById('edit-sound-modal');
    if (modal) {
        modal.classList.add('hidden');
    }
}

// Event listener for modal closing
document.getElementById('edit-sound-modal').addEventListener('click', (e) => {
    const target = e.target;

    // Close the modal if the overlay or a "data-close" element is clicked
    if (target.dataset.close === 'true') {
        closeEditSoundModal();
    }
});


// Ajouter un événement pour l'overlay
document.getElementById('overlay').addEventListener('click', () => toggleRightPanel(true));


window.openEditPanel = openEditPanel; // Rendez la fonction disponible globalement si nécessaire
window.openEditSoundModal = openEditSoundModal;
window.toggleRightPanel = toggleRightPanel;
